import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import LandingPage from "./components/landingPage";
import FloorPlan from "./components/floorPlan";
import Dashboard from "./components/dashboard";
import Header from "./components/header";
import SelectStyle from "./components/selectStyle";
import "@fontsource/poppins/400.css"; 
import "@fontsource/poppins/700.css";

function App() {
  return (
    <BrowserRouter>
      <HeaderWrapper />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/floorplan" element={<FloorPlan />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/selectstyle" element={<SelectStyle />} />
      </Routes>
    </BrowserRouter>
  );
}

function HeaderWrapper() {
  const location = useLocation();
  return location.pathname !== "/" && location.pathname !== "/floorplan" ? <Header /> : null;
}

export default App;
