import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRoom: "Foyer",
  selectedFooterStyle: "Modern Luxury",
  selectedVariant: "01",
  selectedBudget: "Luxury ZX",
  SelectedIndex: 0
};

const queryParamSlice = createSlice({
  name: "queryParam",
  initialState,
  reducers: {
    setSelectedRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    setSelectedFooterStyle: (state, action) => {
      state.selectedFooterStyle = action.payload;
    },
    setSelectedVariant: (state, action) => {
      state.selectedVariant = action.payload;
    },
    setSelectedBudget: (state, action) => {
      state.selectedBudget = action.payload;
    },
    setSelectedFloorPlan: (state, action) => {
      state.selectedFloorPlan = action.payload;
    },
    setSelectedIndex: (state, action) => {
      state.SelectedIndex = action.payload;
    },
  },
});

export const {
  setSelectedRoom,
  setSelectedFooterStyle,
  setSelectedVariant,
  setSelectedBudget,
  setSelectedIndex

} = queryParamSlice.actions;
export default queryParamSlice.reducer;
