import { useEffect } from "react";
import OpenIcon from "../assets/Group 14.png";
import closeIcon from "../assets/Group 24.png";

const MarkersAccordian = ({
  name,
  description,
  finishes,
  top,
  isOpen,
  onToggle,
}) => {
  const accordionStyle = {
    position: "absolute",
    left: "25px",
    top: top > "75%" ? "-150px" : "17px",
    transition: "all 0.5s ease-in-out",
    width: "240px",
    height: "180px",
    zIndex: "29",
  };

  console.log("top", top);

  useEffect(() => {
    onToggle();
  }, []);

  return (
    <div className="accordion-header" onClick={onToggle}>
      <span className=" accordion-toggle fixed z-30   hover:animate-none">
        <span style={{animationDuration:'2s'}} class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
        {isOpen ? (
          <img
            src={closeIcon}
            width={40}
            height={40}
            alt="closeIcon"
            loading="lazy"
          />
        ) : (
          <img
            src={OpenIcon}
            height={40}
            width={40}
            className=""
            loading="lazy"
            alt="openIcon"
          />
        )}
      </span>

      {isOpen && (
        <div
          className="bg-white p-4 rounded-md border-gold border absolute shadow-custom z-0"
          style={accordionStyle}
        >
          <h2 className="text-lg text-gold">{name}</h2>
          <p className="text-sm text-[#7B838D] mt-1">{description}</p>
          {/* <p className="text-base mt-2 text-[#515B67]">Available Finishes:</p>
          <div className="flex flex-row gap-3 justify-start mt-2">
            {finishes?.map((color) => (
              <div
                key={color}
                className="h-8 w-8 rounded-full border border-gray-300"
                style={{ backgroundColor: color }}
              ></div>
            ))}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default MarkersAccordian;
