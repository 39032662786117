import { useDispatch, useSelector } from "react-redux";
import { setSelectedStyle } from "../redux/selectStyleSlice";
import arrow from "../assets/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import jsonData from "../assets/JsonData/4bhk.json";
import { useEffect, useState } from "react";
import luxuryKitchen from "../assets/kitchen zx_01.jpeg";
import bedroom from "../assets/master bedroom zx.jpeg";

const SelectStyle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedStyle = useSelector((state) => state.selectStyle.selectedStyle);

  const kitchenImages = jsonData["Floor Plan"]["Kitchen"] || [];
  const livingRoomImages = jsonData["Floor Plan"]["Living Room"] || [];
  const bedroomImages = jsonData["Floor Plan"]["Bedroom"] || [];
  const [kitchenActiveIndex, setKitchenActiveIndex] = useState(0);
  const [livingRoomActiveIndex, setLivingRoomActiveIndex] = useState(0);
  const [bedroomActiveIndex, setBedroomActiveIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  console.log("kitchenImages", kitchenImages[0]);
  const handleAnimation = () => {
    setAnimationClass("");
    setTimeout(() => {
      setAnimationClass("animate-left-to-right");
    }, 50);
  };

  useEffect(() => {
    handleAnimation();
  }, [kitchenActiveIndex, livingRoomActiveIndex, bedroomActiveIndex]);

  // const getRoomLabel = (imageSrc) => {
  //   if (imageSrc.includes("vx")) return "Premium VX";
  //   if (imageSrc.includes("zx")) return "Luxury ZX";
  //   if (imageSrc.includes("cx")) return "Comfort CX";
  // };

  return (
    <>
      <div className="bg-white px-4 pt-[15px] pb-2 rounded-lg h-[67vh] 2xl:h-[calc(100vh-9vh)]">
        <p className="text-center mt-[4px] mb-[12px]">
          <span className="font-bold text-md">Select Style </span>
          <span className=" text-gray-600 text-md">
            - Choose a style that reflects your vision.
          </span>
        </p>
        <div className="flex mb-[15px] items-center justify-center">
          <button
            onClick={() => dispatch(setSelectedStyle("Modern Luxury"))}
            className={`mr-[6rem] text-[20px] mx-3 py-1 relative ${selectedStyle === "Modern Luxury"
              ? "text-gold font-medium after:content-[''] after:absolute after:w-10 after:h-[2px] after:bg-gold after:left-0 after:bottom-0"
              : "text-[#7B838D] "
              }`}
          >
            Modern Luxury
          </button>
          <button
            onClick={() => dispatch(setSelectedStyle(" Indian Revival"))}
            className={`mr-[6rem] text-[20px] mx-3 py-1 relative ${selectedStyle === " Indian Revival"
              ? "text-gold font-medium after:content-[''] after:absolute after:w-10 after:h-[2px] after:bg-gold after:left-0 after:bottom-0"
              : "text-[#7B838D] font-normal"
              }`}
          >
            Indian Revival
          </button>
          <button
            onClick={() => dispatch(setSelectedStyle("Classical Europena"))}
            className={`text-[20px] mx-3 py-1 relative ${selectedStyle === "Classical Europena"
              ? "text-gold font-medium after:content-[''] after:absolute after:w-10 after:h-[2px] after:bg-gold after:left-0 after:bottom-0"
              : "text-[#7B838D] font-normal"
              }`}
          >
            Classical European
          </button>
        </div>
        <div className="md:flex gap-4 lg:h-[65.5vh] sm:100vh xl:h-[74vh]">
          <div className="relative 2xl:w-[55%] lg:w-[470px] xl:w-[50%] md:my-0  my-2 ">
            <div
              className={`relative sm:h-[65.5vh] h-[40vh]  xl:h-[75vh]   flex items-center justify-center`}
            >
              <Carousel
                autoPlay={true}
                interval={5000}
                transitionTime={1000}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                className="w-full h-full custom-carousel"
                selectedItem={livingRoomActiveIndex}
                onChange={(index) => setLivingRoomActiveIndex(index)}
              >
                {(livingRoomImages.length > 0 ? livingRoomImages : []).map(
                  (image, index) => (
                    <div
                      key={index}
                      className={`relative 2xl:w-[100vw] lg:w-[80vw]  sm:h-[65.5vh] h-[40vh] xl:h-[75vh] `}
                    >
                      <img
                        src={image}
                        alt={`Living Room Image ${index + 1}`}
                        className={`2xl:w-[60vw] sm:h-[65.5vh] h-[40vh] lg:w-[80vw] xl:h-[75vh] object-cover ${index === livingRoomActiveIndex ? animationClass : ""
                          } `}
                      />
                      <div
                        className={`absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-70`}
                      ></div>
                    </div>
                  )
                )}
              </Carousel>
              <div className="absolute lg:bottom-4 sm:bottom-2 bottom-8 left-4 px-2 py-1 rounded ">
                {/* <p className="text-gold">
                  {getRoomLabel(livingRoomImages[livingRoomActiveIndex])}
                </p> */}
                <p className="lg:text-[2rem]  sm:text-[1.5rem]  text-white">
                  Living Room
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 relative xl:h-[100%] 2xl:w-[35%] lg:w-[372px] xl:w-[30%]">
            <div
              className={`relative h-[33vh] xl:h-[36.3vh] flex items-center justify-center`}
            >
              {/* <Carousel
                autoPlay
                interval={5000}
                transitionTime={2000}
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                className="w-full h-[32.5vh] xl:h-[36.3vh] custom-carousel"
                onChange={(index) => setKitchenActiveIndex(index)}
              > */}
              {/* {(kitchenImages.length > 0 ? kitchenImages : []).map(
                (image, index) => ( */}
              <div
                className={`relative w-full h-[32.5vh] xl:h-[36.3vh] rounded-3xl`}
              >
                <img
                  src={luxuryKitchen}
                  alt={`Kitchen Image`}
                  className={`w-full h-[32.5vh] xl:h-[36.3vh] rounded-3xl object-cover`}
                />
                <div
                  className={`absolute inset-0 bg-gradient-to-t  rounded-3xl from-black via-transparent to-transparent opacity-70`}
                ></div>
              </div>
              {/* )
              )} */}
              {/* </Carousel> */}
              <div className="absolute lg:bottom-4 sm:bottom-2 bottom-8 left-4 px-2 py-1 rounded">
                {/* <p className="text-gold">
                  {getRoomLabel(kitchenImages[kitchenActiveIndex])}
                </p> */}
                <p className="lg:text-[2rem] sm:text-[1.5rem]  text-white">
                  Kitchen
                </p>
              </div>
            </div>
            <div
              className={`relative xl:h-[36.3vh]  sm:h-[31vh] h-[32.5vh] flex items-center justify-center`}
            >
              {/* <Carousel
                autoPlay
                interval={5000}
                transitionTime={2000}
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                className="w-full xl:h-[36.3vh] sm:h-[31vh] h-[32.5vh] custom-carousel"
                onChange={(index) => {
                  setBedroomActiveIndex(index);
                }}
              > */}
              {/* {(bedroomImages.length > 0 ? bedroomImages : []).map(
                (image, index) => ( */}
              <div
                className={`relative w-full h-[31vh] xl:h-[36.3vh] rounded-3xl`}
              >
                <img
                  src={bedroom}
                  alt={`Bedroom Image`}
                  className={`w-full sm:h-[31vh] h-[32.5vh] xl:h-[36.3vh] object-cover rounded-3xl`}
                />
                <div
                  className={`absolute inset-0 bg-gradient-to-t from-black rounded-3xl via-transparent to-transparent opacity-70`}
                ></div>
              </div>
              {/* )
              )} */}
              {/* </Carousel> */}
              <div className="absolute lg:bottom-4 sm:bottom-2 bottom-8 left-4 px-2 py-1 rounded">
                {/* <p className="text-gold">
                  {getRoomLabel(bedroomImages[bedroomActiveIndex])}
                </p> */}
                <p className="lg:text-[2rem] sm:text-[1.5rem] text-white">
                  Bedroom
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col xl:h-[74.5vh] gap-4 2xl:w-[350px] lg:w-[278px] xl:w-[20%] lg:justify-between justify-center items-center md:my-0 my-2">
            <div className="bg-[#F6EBDD] h-[57vh] xl:h-[64vh] overflow-hidden rounded-3xl ">
              <div className="px-6 pt-5">
                <p className="text-base leading-7 xl:leading-7 md:leading-7 lg:leading-5 text-[#515B67]">
                  <span className="font-bold">Modern Luxury</span> is about
                  clean, functional design that enhances everyday living. It
                  combines style and comfort for people who want their homes to
                  feel like a retreat from the fast pace of life.
                </p>
                <br />
                <p className="text-base leading-7 xl:leading-7 md:leading-7 lg:leading-5 text-[#515B67]">
                  Designed for people who value both aesthetics and
                  practicality, it focuses on simplicity, high-quality
                  materials, and smart design.
                </p>
              </div>
            </div>
            <button
              onClick={() => navigate("/dashboard?index=0")}
              className="bg-gold hover:bg-[#c6a033] text-md text-white px-6 py-3 rounded-[20px] w-full h-[64px] flex items-center justify-center shadow-md transition-all duration-300"
            >
              Select Style
              <img
                src={arrow}
                alt="Arrow"
                height={24}
                width={24}
                className="ml-4"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default SelectStyle;
