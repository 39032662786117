import ellipse from "../assets/Ellipse.png";
import eye from "../assets/eye.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setSelectedBudget,
  setSelectedFooterStyle,
  setSelectedIndex,
  setSelectedRoom,
  setSelectedVariant,
} from "../redux/queryParam";
import { useEffect, useRef, useState } from "react";
import { setSelectedFloorPlan, setSelectedHideHeader } from "../redux/state";
import jsonData from "../assets/JsonData/4bhk.json";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MarkersAccordian from "./markersAccordian";
import InfoIcon from "../assets/Group 16.png";
import CloseIcon from "../assets/Group 33.png";
import Swiper from "./swiper";

const HomeLayoutPage = () => {
  const childRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedRoom = useSelector((state) => state.queryParam.selectedRoom);
  const isHideHeader = useSelector((state) => state.floorPlan.isHideHeader);
  const selectedStyle = useSelector(
    (state) => state.queryParam.selectedFooterStyle
  );
  const selectedBudget = useSelector(
    (state) => state.queryParam.selectedBudget
  );
  const selectedVariant = useSelector(
    (state) => state.queryParam.selectedVariant
  );
  const selectedIndex = useSelector((state) => state.queryParam.SelectedIndex);
  const modernLuxuryKeys = Object.keys(jsonData["Modern Luxury"]);
  const queryParams = new URLSearchParams(location.search);
  const floorplanParam = queryParams.get("floorplan");
  const menuParam = queryParams.get("room");
  const styleParam = queryParams.get("style");
  const budgetParam = queryParams.get("budget");
  const variantParam = queryParams.get("variant");
  const indexParam = queryParams.get("index");
  const styleData = jsonData["Modern Luxury"][selectedRoom];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  let isMenuClicked = false;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    floorplanParam
      ? dispatch(setSelectedFloorPlan(floorplanParam))
      : queryParams.set("floorplan", "3BHK");
    menuParam
      ? dispatch(setSelectedRoom(menuParam))
      : queryParams.set("room", selectedRoom);
    styleParam
      ? dispatch(setSelectedFooterStyle(styleParam))
      : queryParams.set("style", selectedStyle);
    budgetParam
      ? dispatch(setSelectedBudget(budgetParam))
      : queryParams.set("budget", selectedBudget);
    variantParam
      ? dispatch(setSelectedVariant(variantParam))
      : queryParams.set("variant", selectedVariant);

    variantParam
      ? dispatch(setSelectedIndex(indexParam))
      : queryParams.set("index", indexParam);

    if (
      !menuParam ||
      !styleParam ||
      !budgetParam ||
      !variantParam ||
      !floorplanParam ||
      !indexParam
    ) {

      navigate(`?${queryParams.toString()}`, { replace: true });
    }
  }, []);
 

  const handleMenuClick = (room) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("room", room);
    // Check if  any of the pulse points are active (If yes , toggles)
    openAccordionIndex !==null && setOpenAccordionIndex(null)
    if (jsonData["Modern Luxury"][room]["Comfort CX"]?.images.length === 0) {
      dispatch(setSelectedBudget("Luxury ZX"));
    }
    dispatch(setSelectedRoom(room));
    navigate(`?${queryParams.toString()}`);
    childRef?.current?.handleIndexChange(modernLuxuryKeys.indexOf(room));
  };

  const handleStyleFromFooter = (style) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("style", style);
    dispatch(setSelectedFooterStyle(style));
    navigate(`?${queryParams.toString()}`);
  };

  const handleBudget = (budget) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("budget", budget);
    dispatch(setSelectedBudget(budget));
    navigate(`?${queryParams.toString()}`);
  };

  const handleVariant = (variant) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("variant", variant);
    dispatch(setSelectedVariant(variant));
    navigate(`?${queryParams.toString()}`);
  };

  const isDisplayBudgetButton = () => {
    return selectedIndex < 9;
  };

  const getImage = (room, sb, selectedVariant) => {
    const styleData = jsonData["Modern Luxury"][room];
    const images = [];
    if (styleData) {
      if (room !== "Washrooms") {
        if (styleData[selectedBudget]?.images) {
          const variantIndex = parseInt(selectedVariant) - 1;
          if (styleData[sb].images[variantIndex]) {
            const imagePath = styleData[sb].images[variantIndex].src;
            images.push({
              src: `${window.location.origin}${imagePath}`,
              title: styleData[sb].images[variantIndex].title,
            });
            return images;
          }
          return images;
        }
      }

      if (styleData["Common"] && styleData["Common"].images) {
        return styleData["Common"].images.map((image) => ({
          src: `${window.location.origin}${image.src}`,
          title: image.title,
          map: image.map,
        }));
      }
    }
    return images;
  };

  const getMarkers = () => {
    if (styleData) {
      if (isDisplayBudgetButton()) {
        if (styleData[selectedBudget]?.images) {
          const variantIndex = parseInt(selectedVariant) - 1;
          if (styleData[selectedBudget].images[variantIndex]) {
            const markers =
              styleData[selectedBudget].images[variantIndex].markers;
            return (
              markers.map((marker) => ({
                ...marker,
                top:
                  window.innerWidth > 1366
                    ? marker.top.desktop
                    : marker.top.mobile,
                // Adjust for responsiveness
              })) || []
            );
          }
        }
      }
    }
    return [];
  };

  useEffect(() => {
    //Checks if there's an extra view compared to others (if yes , resets it to '1' on budget change)
    let variantCount =
      styleData[selectedBudget]?.images?.length ||
      styleData[selectedBudget]?.Common?.images?.length;
    if (selectedBudget && selectedVariant > variantCount) {
      dispatch(setSelectedVariant("01"));
      queryParams.set("variant", "01");
    }
    navigate(`?${queryParams.toString()}`);
  }, [selectedRoom, selectedBudget]);

  const getMapImage = () => {
    return styleData?.map ? `${window.location.origin}${styleData.map}` : null;
  };

  const handleZoom = () => {
    dispatch(setSelectedHideHeader(!isHideHeader));
  };

  const getMapImageForWashroom = (index) => {
    console.log(
      "styleData?.Common?.images[index]?.map",
      styleData?.Common?.images[index]?.map
    );
    console.log("styleData", styleData);
    // console.log("styleData.map", styleData.map);
    return styleData?.Common?.images[index]?.map
      ? `${window.location.origin}${styleData.Common.images[index].map}`
      : null;
  };

  const renderDots = () => {
    const totalDots = slides.length;
    return (
      <div className="flex justify-center space-x-1 xl:my-[22px]">
        {Array.from({ length: totalDots }, (_, index) => (
          <>
            <div
              key={index}
              className={`w-2 h-2 rounded-full ${
                selectedIndex == index ? "bg-gold" : "border border-[#7B838D]"
              }`}
            />
          </>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (childRef.current) {
      childRef.current.handleIndexChange(0);
    }
  }, []);

  const handleNextMenu = (room) => {
    const queryParams = new URLSearchParams(location.search);
    if (jsonData["Modern Luxury"][room][selectedBudget]?.images.length === 0) {
      dispatch(setSelectedBudget("Luxury ZX"));
    } else {
      dispatch(setSelectedRoom(room));
    }
    queryParams.set("room", room);
    navigate(`?${queryParams.toString()}`);
  };

  const handleNextRoom = () => {
    const currentIndex = modernLuxuryKeys.indexOf(selectedRoom);
    const nextIndex = (currentIndex + 1) % modernLuxuryKeys.length;
    const nextRoom = modernLuxuryKeys[nextIndex];
    console.log(indexParam);

    if (selectedRoom == "Washrooms" && indexParam == 12) {
      if (!isMenuClicked) handleNextMenu("Foyer");
    } else if (selectedRoom == "Washrooms") {
      if (!isMenuClicked) handleNextMenu("Washrooms");
    } else {
      if (!isMenuClicked) handleNextMenu(nextRoom);
    }
  };

  const handlePrevRoom = () => {
    const currentIndex = modernLuxuryKeys.indexOf(selectedRoom);
    const prevIndex =
      (currentIndex - 1 + modernLuxuryKeys.length) % modernLuxuryKeys.length;
    const prevRoom = modernLuxuryKeys[prevIndex];

    if (selectedRoom == "Washrooms" && indexParam == 9) {
      if (!isMenuClicked) handleNextMenu("Home Office");
    } else if (selectedRoom == "Washrooms") {
      if (!isMenuClicked) handleNextMenu("Washrooms");
    } else {
      if (!isMenuClicked) handleNextMenu(prevRoom);
    }
  };

  const washroomSlides = getImage("Washrooms", "", "").map((image, j) => (
    <div className="relative">
      <div key={`washroom-Washrooms-${j}`}>
        <img
          src={image.src}
          alt="Room Layout"
          className={`w-full ${
            !isHideHeader
              ? "h-[76vh] 2xl:h-[78vh]"
              : "xs:h-[89vh] sm:h-[89vh] md:h-[89vh] lg:h-[89vh] 2xl:h-[91vh]"
          } object-cover`}
        />
      </div>
      {!isHideHeader && (
        <img
          src={getMapImageForWashroom(j)}
          alt="Room Map"
          className="absolute bottom-0 right-0 w-32 md:w-56 md:h-48 bg-black/10 backdrop-blur-lg opacity-100 p-3 rounded-lg"
        />
      )}
    </div>
  ));

  const normalslides = modernLuxuryKeys
    ?.filter((item) => item !== "Washrooms")
    .flatMap((item, index) => (
      <div key={`room-${index}`} className="relative">
        <img
          src={getImage(item, selectedBudget, selectedVariant)[0]?.src}
          alt="Room Layout"
          className={`w-full ${
            !isHideHeader
              ? "h-[76vh] 2xl:h-[78vh]"
              : "xs:h-[89vh] sm:h-[89vh] md:h-[89vh] lg:h-[89vh] 2xl:h-[91vh]"
          } object-cover`}
        />
        {!isHideHeader && (
          <img
            src={jsonData["Modern Luxury"][item].map}
            alt="Room Map"
            className="absolute bottom-0 right-0 w-32 md:w-56 md:h-48 bg-black/10 backdrop-blur-lg opacity-100 p-3 rounded-lg"
          />
        )}
      </div>
    ));

  const slides = [...normalslides, ...washroomSlides];

  useEffect(() => {
    // Close the accordion when the selected room or variant changes
    setOpenAccordionIndex(null);
  }, [selectedRoom, selectedVariant]);

  return (
    <div className="overflow-hidden">
      <div>
        {!isHideHeader && (
          <div className="w-full flex item-center justify-between overflow-x-auto overflow-y-hidden h-[5vh]">
            <ul className="flex justify-between w-full whitespace-nowrap">
              {modernLuxuryKeys.map((item, index) => (
                <a
                  key={item}
                  href="#"
                  onClick={() => handleMenuClick(item)}
                  className={`py-2 text-sm  mx-2
                    ${
                      selectedIndex > 8 && item === "Washrooms"
                        ? "text-gold border-t-[1px] border-gold"
                        : selectedIndex == index
                        ? "text-gold border-t-[1px] border-gold"
                        : "text-[#515B67]"
                    }`}
                >
                  {item}
                </a>
              ))}
            </ul>
          </div>
        )}
        <main
          className={`relative w-screen ${
            !isHideHeader
              ? "h-[76vh] 2xl:h-[78vh]"
              : "xs:h-[89vh] sm:h-[89vh] md:h-[89vh] lg:h-[89vh] 2xl:h-[91vh]"
          }`}
        >
          <>
            <Swiper
              handleNextRoom={handleNextRoom}
              handlePrevRoom={handlePrevRoom}
              slides={slides}
              jsonData={jsonData}
              ref={childRef}
              modernLuxuryKeys={modernLuxuryKeys}
            />
            <p className="absolute bottom-4 left-5 text-white font-normal text-2xl">
              {
                getImage(
                  modernLuxuryKeys[selectedIndex],
                  selectedBudget,
                  selectedVariant
                )[0]?.title
              }
            </p>
            {selectedIndex > 8 && (
              <p className="absolute bottom-4 left-5 text-white font-normal text-2xl">
                {
                  getImage("Washrooms", selectedBudget, selectedVariant)[
                    selectedIndex - 9
                  ]?.title
                }
              </p>
            )}

            <div className="left-[45%] absolute bottom-0  text-white font-normal text-2xl bg-[#FFFFFFF5] h-[45px] px-4 rounded-t-xl ">
              <p className="text-[#7B838D] text-[11px] text-center xl:hidden">
                Swipe left for more
              </p>
              {renderDots()}
            </div>

            {getMarkers().map((marker, index) => (
              <div
                key={index}
                className="absolute cursor-pointer"
                style={{
                  top: marker.top,
                  left: marker.left,
                  transition: "transform 0.3s ease, opacity 0.3s ease",
                  color: "white",
                }}
              >
                <MarkersAccordian
                  name={marker.text}
                  description={marker.desc}
                  top={marker.top}
                  finishes={marker.finishes}
                  isOpen={openAccordionIndex === index}
                  onToggle={() => {
                    setOpenAccordionIndex(
                      openAccordionIndex === index ? null : index
                    );
                  }}
                />
              </div>
            ))}
          </>

          {!isHideHeader &&
            (isModalOpen ? (
              <img
                src={CloseIcon}
                className="z-10 absolute -top-1 -left-1 cursor-pointer "
                onClick={toggleModal}
                alt="InfoIcon"
              />
            ) : (
              <img
                src={InfoIcon}
                className="z-10 absolute top-2 left-2 cursor-pointer "
                onClick={toggleModal}
                alt="InfoIcon"
              />
            ))}

          {isModalOpen && (
            <div className=" top-32 z-40 left-12 fixed inset-0">
              <div
                className="bg-white/90 rounded-lg shadow-lg z-40"
                style={{ width: "256px", height: "537px" }}
              >
                <div className="p-4">
                  <div className="mt-8 mb-4">
                    <h2 className="text-5xl font-light  text-gold tracking-wider">
                      MODERN{" "}
                    </h2>
                    <h2 className="text-5xl font-medium tracking-widest text-gold">
                      LUXURY{" "}
                    </h2>
                  </div>

                  <p className="text-base leading-6 font-normal text-[#515B67] ">
                    Modern Luxury is about clean, functional design that
                    enhances everyday living. It combines style and comfort for
                    people who want their homes to feel like a retreat from the
                    fast pace of life.
                  </p>
                  <p className="text-base leading-6 font-normal text-[#515B67] mt-2 ">
                    Designed for people who value both aesthetics and
                    practicality, it focuses on simplicity, high-quality
                    materials, and smart design.
                  </p>
                </div>
              </div>
            </div>
          )}

          <button
            onClick={handleZoom}
            className="absolute top-2 right-2 m-1 bg-white text-white rounded-md shadow-md text-xs md:text-sm z-10"
          >
            <div className="flex items-center  py-[10px] px-[12px] bg-[#d2d2d2] text-base border rounded-[4px] text-black">
              <img
                src={isHideHeader ? eye : ellipse}
                alt="Maximize"
                className="2xl:w-7 2xl: h-[14px]  mr-2"
              />
              <p className="w-[15px] h-4 mb-1"> UI</p>
            </div>
          </button>
        </main>
      </div>

      <footer className="bg-white flex flex-col sm:pt-2 md:flex-row items-center lg:py-2 space-y-2 md:space-y-0 z-10">
        <div className="ml-2 mr-2 flex flex-wrap gap-3 text-xs items-center lg:border-r sm:border-0 border-gray-300">
          <div>FILTER:</div>
          <div className="lg:pl-2 text-gray-600">Styles:</div>
          <button
            className={`2xl:p-2 lg:p-1 p-2 border 2xl:w-28 lg:w-28 rounded-full h-[44px] ${
              selectedStyle === "Modern Luxury"
                ? "text-gold border-gold "
                : "text-gray-600"
            }`}
            onClick={() => handleStyleFromFooter("Modern Luxury")}
          >
            Modern Luxury
          </button>
          <button
            className={`2xl:p-2 lg:p-1 p-2 border 2xl:w-28 lg:w-28 rounded-full h-[44px] ${
              selectedStyle === " Indian Revival"
                ? "text-gold border-gold "
                : "text-gray-600"
            }`}
            onClick={() => handleStyleFromFooter(" Indian Revival")}
          >
            Indian Revival
          </button>
          <button
            className={`2xl:p-2 lg:p-1 p-2 border lg:w-[8.5rem] rounded-full h-[44px] mr-2 ${
              selectedStyle === "Classical Europena"
                ? "text-gold border-gold "
                : "text-gray-600"
            }`}
            onClick={() => handleStyleFromFooter("Classical Europena")}
          >
            Classical European
          </button>
        </div>

        <div
          className={`flex flex-wrap  ${
            isDisplayBudgetButton() ? "gap-3" : ""
          }  text-xs items-center  sm:border-0  border-gray-300`}
        >
          <div
            className={`ml-2 text-gray-600 ${
              isDisplayBudgetButton() ? " " : "mx-2"
            }`}
          >
            Budget:
          </div>
          {isDisplayBudgetButton() ? (
            <>
              <button
                onClick={() => handleBudget("Luxury ZX")}
                className={`2xl:p-2 lg:p-1 p-2 border 2xl:w-28 lg:w-24  rounded-full h-[44px] ${
                  selectedBudget === "Luxury ZX"
                    ? "text-gold border-gold "
                    : "text-gray-600"
                }`}
              >
                Luxury ZX
              </button>
              <button
                onClick={() => handleBudget("Premium VX")}
                className={`2xl:p-2 lg:p-1 p-2 border 2xl:w-28 lg:w-24 rounded-full h-[44px] ${
                  selectedBudget === "Premium VX"
                    ? "text-gold border-gold "
                    : "text-gray-600"
                }`}
              >
                Premium VX
              </button>
              {styleData["Comfort CX"]?.images.length > 0 && (
                <button
                  onClick={() => handleBudget("Comfort CX")}
                  className={`2xl:p-2 lg:p-1 p-2 border 2xl:w-28 lg:w-24 rounded-full h-[44px] ${
                    selectedBudget === "Comfort CX"
                      ? "text-gold border-gold "
                      : "text-gray-600"
                  }`}
                >
                  Comfort CX
                </button>
              )}
            </>
          ) : (
            <p className="text-gray-600">
              This room has a style common across all budgets.
            </p>
          )}
        </div>

        {isDisplayBudgetButton() && (
          <div className="ml-2">
            {styleData[selectedBudget] &&
              styleData[selectedBudget]?.images.length > 1 && (
                <div className="flex flex-wrap w-full  lg:border-l gap-3 text-xs items-center">
                  <div className="text-gray-600 ml-4">Views:</div>
                  {styleData[selectedBudget].images.map((variant, index) => (
                    <>
                      <button
                        key={variant}
                        className={`2xl:py-3 2xl:px-2 p-2 border rounded-full  h-[44px] w-[44px] ${
                          selectedVariant ===
                          (index + 1).toString().padStart(2, "0")
                            ? "text-gold border-gold "
                            : "text-gray-600"
                        }`}
                        onClick={() =>
                          handleVariant((index + 1).toString().padStart(2, "0"))
                        }
                      >
                        {String(index + 1).padStart(2, "0")}
                      </button>
                    </>
                  ))}
                </div>
              )}
          </div>
        )}
      </footer>
    </div>
  );
};

export default HomeLayoutPage;
