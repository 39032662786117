import { configureStore } from "@reduxjs/toolkit";
import floorPlanReducer from "./state";
import selectStyleReducer from "./selectStyleSlice";
import queryParamReducer from "./queryParam";

const store = configureStore({
  reducer: {
    floorPlan: floorPlanReducer,
    selectStyle: selectStyleReducer,
    queryParam: queryParamReducer,
  },
});

export default store;
