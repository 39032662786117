import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSelectedIndex } from '../redux/queryParam';

const Swiper = forwardRef(({ slides, modernLuxuryKeys, jsonData, handlePrevRoom, handleNextRoom }, ref) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [targetIndex, setTargetIndex] = useState(0);
    const swiperRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isButtonPressed, setIsButtonPressed] = useState(false);
    const selectedStyle = useSelector(
        (state) => state.queryParam.selectedFooterStyle
    );
    const selectedBudget = useSelector(
        (state) => state.queryParam.selectedBudget
    );
    const selectedVariant = useSelector(
        (state) => state.queryParam.selectedVariant
    );
    const selectedRoom = useSelector((state) => state.queryParam.selectedRoom);


    const queryParams = new URLSearchParams(location.search);
    const newKeys = JSON.parse(JSON.stringify(modernLuxuryKeys));
    newKeys.pop();
    newKeys.push(...jsonData["Modern Luxury"]["Washrooms"].Common.images.map((image) => image.title));

    useEffect(() => {
        setTimeout(() => {
            queryParams.set("index", targetIndex);
            navigate(`?${queryParams.toString()}`);
            dispatch(setSelectedIndex(targetIndex))
        }, 100);
    }, [targetIndex])

    // useEffect(() => {
    //     setTimeout(() => {
    //         const index = queryParams.get("index");
    //         navigate(`?${queryParams.toString()}`);
    //         setTargetIndex(index);
    //         dispatch(setSelectedIndex(index))
    //     }, 100);
    // }, []);
    useEffect(() => {
        const index = queryParams.get("index") || 0;
        queryParams.set("room", selectedRoom);
        queryParams.set("style", selectedStyle);
        queryParams.set("budget", selectedBudget);
        queryParams.set("variant", selectedVariant);
        setTargetIndex(index);
        dispatch(setSelectedIndex(index));
        navigate(`?${queryParams.toString()}`);
    }, []);

    const handleNext = () => {
      console.log(slides.length);
      
        const count = +targetIndex + 1;

        if (count > slides.length) {
            queryParams.set("room", "Foyer");
        }
        setTargetIndex(count > slides.length - 1 ? 0 : count);
        handleNextRoom();
    };

    const handlePrev = () => {
        const count = +targetIndex - 1;
        if (count > -1) {
            queryParams.set("room", "Washrooms");
        }
        setTargetIndex(count > -1 ? count : slides.length - 1);
        handlePrevRoom();
    };

    useImperativeHandle(ref, () => ({
        handleIndexChange
    }));

    const handleIndexChange = (index) => {
        setTargetIndex(index);
    };

    const handleTouchStart = (e) => {
        if (!isButtonPressed) {
            const touch = e.touches[0];
            setStartX(touch.clientX);
        }
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        setCurrentX(touch.clientX);
    };

    const handleTouchEnd = () => {
        if (!isButtonPressed) {
            const diffX = startX - currentX;
            if (diffX > 50) {
                handleNext(); // Swipe left
            } else if (diffX < -50) {
                handlePrev(); // Swipe right
            }
        }
    };

    const [startX, setStartX] = useState(0);
    const [currentX, setCurrentX] = useState(0);

    useEffect(() => {
        const swiper = swiperRef.current;
        if (currentIndex !== targetIndex) {
            setCurrentIndex(targetIndex);
        } else {
            swiper.style.transform = `translateX(-${currentIndex * 100}%)`;
        }
    }, [currentIndex, targetIndex, slides]);

    return (
        <div
            className="relative w-full h-full overflow-hidden"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div
                ref={swiperRef}
                className="flex transition-transform duration-500 ease-in-out"
            >
                {slides.map((slide, index) => (
                    <div key={index} className="w-full flex-shrink-0">
                        {slide}
                    </div>
                ))}
            </div>
            <div className="absolute top-[43%] width-[100px] height-[100px] left-0">
                <button
                    className="bg-zinc-100 h-[104px] w-[114px] p-4 flex flex-col items-start justify-center rounded-tr-lg rounded-br-lg shadow-md hover:bg-zinc-200 transition-all ease-in-out duration-100 text-start"
                    onMouseDown={() => setIsButtonPressed(true)}
                    onMouseUp={() => setIsButtonPressed(false)}
                    onTouchStart={() => setIsButtonPressed(true)}
                    onTouchEnd={() => setIsButtonPressed(false)}
                    onClick={() => handlePrev()}
                >
                    <span className="text-[#515B67] font-normal text-xs">PREV</span>
                    <p className="text-[#515B67] font-light text-base mt-1">
                        {newKeys[
                            (targetIndex -
                                1 +
                                newKeys.length) %
                            newKeys.length
                        ] === "Washrooms"
                            ? "Wash-rooms"
                            : newKeys[
                            (targetIndex -
                                1 +
                                newKeys.length) %
                            newKeys.length
                            ]}
                    </p>
                </button>
            </div>
            <div className="absolute top-[43%] width-[100px] height-[100px] right-0">
                <button
                    className="text-start bg-zinc-100 h-[104px] w-[114px] p-4 flex flex-col items-start justify-center rounded-tl-lg rounded-bl-lg shadow-md hover:bg-zinc-200 transition-all ease-in-out duration-100"
                    onMouseDown={() => setIsButtonPressed(true)}
                    onMouseUp={() => setIsButtonPressed(false)}
                    onTouchStart={() => setIsButtonPressed(true)}
                    onTouchEnd={() => setIsButtonPressed(false)}
                    onClick={() => handleNext()}
                >
                    <span className="text-[#515B67] font-normal text-xs">NEXT</span>
                    <p className="text-[#515B67] font-light text-base mt-1">
                        {
                            newKeys[
                            (targetIndex + 1) %
                            newKeys.length
                            ]
                        }
                    </p>
                </button>
            </div>
        </div>
    );
});

export default Swiper;