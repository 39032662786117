import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFloorPlan: "",
  isHideHeader: false,
};

const floorPlanSlice = createSlice({
  name: "floorPlan",
  initialState,
  reducers: {
    setSelectedFloorPlan: (state, action) => {
      state.selectedFloorPlan = action.payload;
    },
    setSelectedHideHeader: (state, action) => {
      state.isHideHeader = action.payload;
    },
  },
});

export const { setSelectedFloorPlan, setSelectedHideHeader } =
  floorPlanSlice.actions;
export default floorPlanSlice.reducer;
