import Arrow from "../assets/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import logo1 from "../assets/the-project-logo.svg";
import logo2 from "../assets/home-lane.svg";
import palatial from "../assets/Palatial.webp";
const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <div className="overflow-hidden flex justify-end p-16 h-full w-full">
      <img
        src={palatial}
        alt="Landing"
        loading="lazy"
        className="h-full w-full object-cover absolute top-0 left-0"
      />
      <div className="flex flex-col self-start p-4 z-10 lg:mt-6 pt-6 md:mt-4 sm:mt-0 xl:w-[900px] lg:w-[700px] 2xl:w-[71%]  md:w-[700px] md:-mr-[13rem] sm:w-[300px] sm:mr-[0.1rem]">
        <div className="flex flex-row items-center">
          <img
            src={logo1}
            alt="Logo"
            className="lg:w-[430px] lg:h-[82px] md:w-[100px] md:h-[30px] sm:w-[150px] sm:h-[80px]"
          />
          {/* <img
            src={logo2}
            alt="Logo"
            className="lg:w-[216px] lg:h-[32px] ml-4 mt-3 md:w-[100px] md:h-[30px] sm:w-[90px] sm:h-[20px]"
          /> */}
        </div>

        <div className="text-gold text-start font-bold text-lg mt-12 2xl:text-3xl">
          Welcome to your new home!
        </div>

        <p className="mt-3 text-start text-[#1E1E1E] font-thin text-base xl:w-[600px] lg:w-[500px] md:w-[400px] sm:w-[300px] leading-6 xl:leading-6 md:leading-6 sm:leading-6 2xl:text-xl">
          Now, let's elevate it to unparalleled luxury. Our design team will
          work with you to create a space that feels warm, personal, and deeply
          inviting—one that reflects your story and style in every detail.
        </p>
        <p className=" text-start text-[#1E1E1E] font-thin text-base xl:w-[600px] lg:w-[500px] md:w-[400px] sm:w-[300px] leading-6 xl:leading-6 md:leading-6 sm:leading-6 mt-8 2xl:text-xl">
          Let’s craft a home you’ll cherish every day
        </p>

        <button
          onClick={() => navigate("/floorplan")}
          className="bg-gold text-md text-white lg:px-6 lg:py-3 md:px-4 md:py-2 sm:px-2 sm:py-1 rounded-md mt-8 lg:w-[204px] lg:h-[48px] md:w-[150px] md:h-[40px] sm:w-[150px] sm:h-[30px] flex items-center justify-center"
        >
          <span className="lg:text-lg md:text-base sm:text-sm 2xl:text-xl">
            Let's begin{" "}
          </span>
          <img
            src={Arrow}
            alt="Arrow"
            height={24}
            width={24}
            className="ml-4"
          />
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
