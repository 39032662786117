import arrow from "../assets/arrow-right.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedFloorPlan } from "../redux/state";
import homeLaneLogo from "../assets/home-lane.svg";
import thePalatialLogo from "../assets/the-project-logo.svg";
import palatial from "../assets/Palatial.png";
import three from "../assets/Mask group.png";
import four from "../assets/fourbg.png";
import fourBHK from "../assets/4bhk.png";
import threeBHK from "../assets/3bhk.png";

const FloorPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPlan = useSelector(
    (state) => state.floorPlan.selectedFloorPlan
  );

  const handlePlanSelect = (plan) => {
    dispatch(setSelectedFloorPlan(plan));
  };

  const handleNextClick = () => {
    if (selectedPlan) {
      navigate(`/selectstyle?floorplan=${selectedPlan}`);
    }
  };

  return (
    <div className="overflow-hidden flex justify-end p-16  w-screen">
      <div
        onClick={() => navigate("/")}
        className="h-[4rem] absolute top-0 z-20 left-0 flex justify-between items-center "
      >
        <img
          className=" w-[18.9rem] h-[3.5rem] ml-5 cursor-pointer"
          src={thePalatialLogo}
          alt="Logo"
        />
        {/* <img
          className="w-[9rem] h-[2rem] ml-3 mt-1 cursor-pointer"
          src={homeLaneLogo}
          alt="Logo"
        /> */}
      </div>

      <img
        src={palatial}
        alt="Landing"
        loading="lazy"
        className="h-full w-full object-cover absolute top-0 left-0  "
      />
      <div className="flex flex-col absolute md:top-40  2xl:top-24 right-11 lg:right-7 xl:right-12 ">
        <p className="mb-4">
          <span className="font-bold  text-lg 2xl:inline lg:block lg:text-base">
            Select Floorplan
          </span>
          <span className=" 2xl:inline lg:hidden ">-</span>
          <span className=" text-gray-600 text-lg lg:text-sm">
            Please select the floor plan that matches your unit to begin customising your space.
          </span>
        </p>

        <div className="flex space-x-4 ">
          <div
            className={`2xl:w-[25rem] 2xl:h-[30rem]  border-solid border-2 rounded-[1.2rem] cursor-pointer 
              md:w-[21rem] md:h-[19.6rem] h-[19rem] w-[15rem] lg:w-[17rem] lg:h-[19rem]
              ${selectedPlan === "3BHK" ? "border-gold" : "border-gray-400"}`}
            onClick={() => handlePlanSelect("3BHK")}
          >
            <div
              className={`2xl:w-full 2xl:h-[23rem] border-dotted border-b-2       
                          md:w-[16.9rem] md:h-[15rem] w-[12rem]  h-[15rem] md:rounded-[0.2rem] relative
                        ${selectedPlan === "3BHK"
                  ? "border-gold"
                  : "border-gray-200"
                }`}
            >
              <div className="md:w-[16.3rem] md:h-[15rem] 2xl:h-full 2xl:w-full ">
                <img
                  src={three}
                  className="rounded-[1.2rem] h-[calc(100%-1rem)] "
                  alt="Three BHK BackGround"
                />
              </div>
              <img
                src={threeBHK}
                alt="3BHK Floor Plan"
                className="w-[261px] h-[193px] absolute top-6 -left-1 2xl:top-0 2xl:w-[22.5rem] 2xl:h-[23rem] 2xl:left-4"
                loading="lazy"
              />
              <h3
                className={`text-center text-3xl font-light mt-2 relative lg:left-0 2xl:mt-7 ${selectedPlan === "3BHK" ? "text-gold" : "text-gray-600"
                  }`}
              >
                3BHK
              </h3>
            </div>{" "}
          </div>
          <div
            className={`2xl:w-[25rem] 2xl:h-[30rem]  border-solid border-2 rounded-[1.2rem] cursor-pointer 
                             md:w-[21rem] md:h-[19.6rem] h-[19rem] w-[15rem] lg:w-[17rem] lg:h-[19rem] 
               ${selectedPlan === "4BHK" ? "border-gold" : "border-gray-400"}`}
            onClick={() => handlePlanSelect("4BHK")}
          >
            <div
              className={`2xl:w-full 2xl:h-[23rem] border-dotted border-b-2 mx-auto   
                          md:w-[16.9rem] md:h-[15rem] w-[12rem]  h-[15rem] md:rounded-[0.2rem] relative
                        ${selectedPlan === "4BHK"
                  ? "border-gold"
                  : "border-gray-200  "
                }`}
            >
              <div className="md:w-[16.3rem] md:h-[15rem] 2xl:h-full 2xl:w-full ">
                <img
                  src={four}
                  className="w-full h-[calc(100%-1rem)] object-cover  rounded-[1rem] "
                  alt="Three BHK BackGround"
                />
              </div>
              <img
                src={fourBHK}
                alt="4BHK Floor Plan"
                className="w-[265px] h-[160px] absolute top-10 lg:left-1 2xl:top-0 2xl:w-[22.5rem] 2xl:h-[23rem] 2xl:left-4"
                loading="lazy"
              />
              <h3
                className={`text-center text-3xl font-light mt-2 relative lg:left-0 2xl:mt-7 ${selectedPlan === "4BHK" ? "text-gold" : "text-gray-600"
                  }`}
              >
                4BHK
              </h3>
            </div>
          </div>
        </div>
        <div className="flex justify-end mr-6 items-end">
          <button
            disabled={selectedPlan === ""}
            className={`${selectedPlan === "" ? "bg-[#D9D9D9]" : "bg-gold"} text-md text-white px-6 py-3 rounded-md mt-5 w-[204px] h-[48px] flex items-center justify-center`}
            onClick={handleNextClick}
          >
            Next{" "}
            <img
              src={arrow}
              alt="Arrow"
              height={24}
              width={24}
              className="ml-4"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FloorPlan;
