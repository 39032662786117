import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectedFloorPlan } from "../redux/state";
import thePalatialLogo from "../assets/the-project-logo.svg";
import homeLaneLogo from "../assets/home-lane.svg";
import arrow from "../assets/Vector.png";
const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedFloorPlan = useSelector(
    (state) => state.floorPlan.selectedFloorPlan
  );
  const isHideHeader = useSelector((state) => state.floorPlan.isHideHeader);

  const handleSelectChange = (event) => {
    const selectedPlan = event.target.value;
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("floorplan", selectedPlan);
    dispatch(setSelectedFloorPlan(selectedPlan));
    navigate(`?${queryParams.toString()}`);
  };

  return isHideHeader ? (
    <> </>
  ) : (
    <div className="h-[8vh] border-b-[0.028rem] flex justify-between items-center ">
      {/* <img
        className="w-[17.93rem] h-[3.32rem] my-[0.75rem] ml-[1.5rem] cursor-pointer"
        src={logo}
        alt="Logo"
        onClick={() => navigate("/")}
      /> */}

      <div className="flex flex-row items-center">
        <img
          className=" w-[18.9rem] h-[13.5rem] ml-5 cursor-pointer"
          src={thePalatialLogo}
          alt="Logo"
          onClick={() => navigate("/")}
        />
        {/* <img
          className="w-[9rem] h-[2rem] ml-3 mt-1 cursor-pointer"
          src={homeLaneLogo}
          onClick={() => navigate("/")}
          alt="Logo"
        /> */}
      </div>

      <div>
        {location.pathname !== "/floorplan" && (
          <div className="relative">
            <select
              className="mr-[1.5rem] p-1 pr-8 text-lg font-medium text-gold bg-transparent appearance-none"
              onChange={handleSelectChange}
              value={selectedFloorPlan}
            >
              <option
                value="3BHK"
                className="text-lg font-semibold text-gold bg-transparent"
              >
                3BHK Layout
              </option>
              <option
                value="4BHK"
                className="text-lg font-semibold text-gold bg-transparent"
              >
                4BHK Layout
              </option>
            </select>
            {/* Dropdown arrow */}
            <span className="absolute top-1/2 right-7 transform -translate-y-1/2 pointer-events-none">
              <img src={arrow} alt="Dropdown arrow" />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
